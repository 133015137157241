import React, { useEffect } from 'react';
import { RichText } from '../richText/richText';
import { Link } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextListSection = ({ title, contentList, buttonLabel, buttonLink, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="text-list-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl" style={{paddingTop: 80}}>
        <h2 className="mb-6 heading-2 text-canary">{title}</h2>
      </div>
      {contentList.map((listItem, index) => {
          return (
            <div className="mx-auto max-w-xxl" style={{paddingTop: 40}} key={index}>
                <div>
                  <h3 className="mb-6 heading-2">{listItem.title}</h3>
                </div>             
                <div className="">
                  <RichText nodeLocale={nodeLocale} raw={listItem.richText} />
                </div>
                <div>
                  {listItem.ctaButtonLinks &&
                    <div className="mt-6 text-center xs:flex flex-wrap">
                      {listItem.ctaButtonLinks.map((link, index) => {
                        return (
                          <div className="mb-4 px-4" key={index}>
                            <Link className="btn btn--turquoise" to={link.linkUrl}>{link.linkName}</Link>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
            </div>
          )
      })}
    </section>
  )
}

export default TextListSection;