import React, { Fragment, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { checkValidity } from '../../shared/utility';
import Spinner from '../spinner/spinner';
import { navigate } from "gatsby"

const SimpleContactDialog = (props, ref) => {

  const {sectionTitle, firstName, lastName, email, texts, ctaButtonLinks, locale} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  const [formValues, setFormValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: ''
  });

  const [invalidMessages, setInvalidMessages] = useState({
    invalidFirstname: '',
    invalidLastname: '',
    invalidEmail: '',
    invalidMessage: ''
  });

  useImperativeHandle(ref, () => ({
    openContactDialog(){
      setOpen(true);
    }
  }), [])

  function checkValidityElements() {
    if (!checkValidity(formValues.firstname, { required: true })) {
      setInvalidMessages({...invalidMessages, invalidFirstname: texts.requiredFirstName});
    } else {
      setInvalidMessages({...invalidMessages, invalidFirstname: ''});
    }

    if (!checkValidity(formValues.lastname, { required: true })) {
      setInvalidMessages({...invalidMessages, invalidLastname: texts.requiredLastName});
    } else {
      setInvalidMessages({...invalidMessages, invalidLastname: ''});
    }

    if (!checkValidity(formValues.email, { required: true, isEmail: true })) {
      setInvalidMessages({...invalidMessages, invalidEmail: texts.invalidEmail});
    } else if (formValues.email.includes("@gmail.com") ||
        formValues.email.includes("@hotmail.com") ||
        formValues.email.includes("@live.com") ||
        formValues.email.includes("@yahoo.com") ||
        formValues.email.includes("@outlook.com")) {
      setInvalidMessages({...invalidMessages, invalidEmail: texts.excludedEmail});
    } else {
      setInvalidMessages({...invalidMessages, invalidEmail: ''});
    }

    if (!checkValidity(formValues.message, { required: true })) {
      setInvalidMessages({...invalidMessages, invalidMessage: texts.requiredMessage});
    } else {
      setInvalidMessages({...invalidMessages, invalidMessage: ''});
    }
    
    return !(invalidMessages.invalidFirstname || 
                    invalidMessages.invalidLastname || 
                    invalidMessages.invalidEmail || 
                    invalidMessages.invalidMessage);
  }

  function handleInputChange(e) {
    e.persist();
    setErrorMessage('');
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }));

    switch (e.target.name) {
      case 'firstname':
        if (!checkValidity(e.target.value, { required: true })) {
          setInvalidMessages({...invalidMessages, invalidFirstname: texts.requiredFirstName});
        } else {
          setInvalidMessages({...invalidMessages, invalidFirstname: ''});
        }
        break;
      case 'lastname':
        if (!checkValidity(e.target.value, { required: true })) {
          setInvalidMessages({...invalidMessages, invalidLastname: texts.requiredLastName});
        } else {
          setInvalidMessages({...invalidMessages, invalidLastname: ''});
        }
        break;
      case 'email':
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(texts.invalidEmail);
        } else if (e.target.value.includes("@gmail.com") ||  
              e.target.value.includes("@hotmail.com") || 
              e.target.value.includes("@live.com") || 
              e.target.value.includes("@yahoo.com") || 
              e.target.value.includes("@outlook.com")) {
          setInvalidMessages({...invalidMessages, invalidEmail: texts.excludedEmail}); 
        } else {
          setInvalidMessages({...invalidMessages, invalidEmail: ''});
        }
        break;
      default:
        break;
    }
  }

  const handleSubmitContact = async event => {
    event.preventDefault();
    const valid = checkValidityElements();
    if (!!valid) {
      setLoading(true);
      const data = new FormData(event.target)
      const success = await fetch("https://edita660.activehosted.com/proc.php", {
        method: "POST",
        body: data,
        mode: "no-cors",
      });

      if (!!success) {
        if (typeof window !== 'undefined') {
          // form submission, dataLayer spec
          // when the form is successfully submitted
          const formName = texts.send.toLowerCase() === 'send' ? 'Send Message' : 'Lähetä viesti'
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'formSubmit',
            'formName' : formName, // esim. 'Send Message', 'Lähetä viesti' tai 'Yhteydenottolomake', 'Contact form'
          });
        }
        setErrorMessage('');
        setInvalidMessages({...invalidMessages, invalidEmail: ''});
        setSuccessMessage(texts.thankyou);
        formValues.firstname = "";
        formValues.lastname = "";
        formValues.email = "";
        ctaButtonLinks?.map((link, index) => {
          // Check if link is external, internal or an asset link
          let url;

          if (link?.linkUrl) {
            url = link?.linkUrl;
          } else if (link?.addAsset?.file.url) {
            url = link.addAsset.file.url;
          } else {
            url = `/${locale}/${link?.slug}`;
          }

          navigate(url);
        })
      } else {
        setErrorMessage(texts.existingContact);
        setSuccessMessage('');
      }
      setLoading(false);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
        initialFocus={cancelButtonRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div style={{background: '#574A71' }} className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20">
                  <div className="mx-auto max-w-screen-lg">
                    {sectionTitle &&
                      <header className="mb-12">
                        <h2 className="heading-2 text-canary text-center">{texts?.simpleContactFormTitle}</h2>
                      </header>
                    }

                    <div className="flex flex-wrap -mx-5 mb-4">
                      <div className="w-full px-5">
                        {!!errorMessage &&
                          <p>{errorMessage}</p>
                        }
                        {!!successMessage &&
                          <p>{successMessage}</p>
                        }
                      </div>
                      {loading && <Spinner />}
                    </div>
                    {(!successMessage && !loading) &&
                      <form onSubmit={e => handleSubmitContact(e)} id="contact-form" className="medium-title-book text-brightTurquoiseApprox">
                        <input type="hidden" name="u" value="10" />
                        <input type="hidden" name="f" value="10" />
                        <input type="hidden" name="s" />
                        <input type="hidden" name="c" value="0" />
                        <input type="hidden" name="m" value="0" />
                        <input type="hidden" name="act" value="sub" />
                        <input type="hidden" name="v" value="2" />
                        <input type="hidden" name="or" value="3eb122b02ea12f6931b2659414d0f502" />
                        <div className="flex flex-wrap -mx-5 mb-12">
                          <div className="w-full md:w-1/2 px-5 mb-4 md:mb-0">
                            <label className="sr-only" htmlFor="first-name">{firstName}</label>
                            <input onChange={handleInputChange} value={formValues.firstname} id="firstname" name="firstname" type="text" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={firstName + "*"} required />
                            {!!invalidMessages.invalidFirstname && <p className="text-violetRed mt-2">{invalidMessages.invalidFirstname}</p> }
                          </div>
                          <div className="w-full md:w-1/2 px-5">
                            <label className="sr-only" htmlFor="last-name">{lastName}</label>
                            <input onChange={handleInputChange} value={formValues.lastname} id="lastname" name="lastname" type="text" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={lastName + "*"} required />
                            {!!invalidMessages.invalidLastname && <p className="text-violetRed mt-2">{invalidMessages.invalidLastname}</p> }
                          </div>
                        </div>
                        <div className="flex flex-wrap -mx-10 mb-12">
                          <div className="w-full md-10 px-10 mb-4 md:mb-0">
                            <label className="sr-only" htmlFor="email">{email}</label>
                            <input onChange={handleInputChange} value={formValues.email} id="email" name="email" type="email" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={email + "*"} required />
                            {!!invalidMessages.invalidMessage && <p className="text-violetRed mt-2">{invalidMessages.invalidMessage}</p> }
                          </div>
                        </div>
                        <div className="mt-6 w-full px-5 text-center">
                          <button type="submit" ref={cancelButtonRef} className="btn btn--turquoise" required>{texts?.send}</button>
                        </div>
                      </form>
                    }
                  </div>
                </section>
              </div>      
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default forwardRef(SimpleContactDialog);