import React, { Fragment, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { checkValidity } from '../../shared/utility';
import Spinner from '../spinner/spinner';
import { IoIosClose } from "react-icons/io";

const NewsletterDialog = (props, ref) => {

  const { email, texts } = props;

  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openNewsletterDialog() {
      setOpen(true);
    }
  }), [])

  const closeDialog = () => {
    setOpen(false)
  };

  const [formNewsletterValues, setFormNewsletterValues] = useState({
    email: ''
  });
  const [errorMessageNewsletter, setErrorMessageNewsletter] = useState('');
  const [successMessageNewsletter, setSuccessMessageNewsletter] = useState('');
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);

  function handleInputChangeNewsletter(e) {
    e.persist();
    setErrorMessageNewsletter('');
    setFormNewsletterValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }));

    switch (e.target.name) {
      case 'email':
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessageNewsletter(texts.invalidEmail);
        } else {
          setErrorMessageNewsletter('');
        }
        break;
      default:
        break;
    }
  }

  const handleSubmitNewsletter = async event => {
    event.preventDefault();
    setLoadingNewsletter(true);
    const data = new FormData(event.target)
    const success = await fetch("https://edita660.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors",
    });

    if (success) {
      if (typeof window !== 'undefined') {
        // Contact page for newsletter subscription, datalayer. When the email is sent successfully
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event' : 'newsletterSubscibe',
        'position' : 'contactPage'
        });
      }
      formNewsletterValues.email = "";
      setSuccessMessageNewsletter(texts.thankyouNewsletterMessage);
    } else {
      setErrorMessageNewsletter(texts.failedNewsletterMessage);
    }
    setLoadingNewsletter(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
        initialFocus={cancelButtonRef}
      >

        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div style={{ background: '#574A71' }} className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border-4 border-canary">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20 relative">
                  <div className="absolute top-0 left-0 text-canary cursor-pointer" onClick={closeDialog}><IoIosClose size={54} /></div>
                  <div className="mx-auto max-w-screen-lg">
                    <header className="mb-12">
                      <h2 className="heading-2 text-canary text-center">{texts.subscribeOurNewsletterTitle}</h2>
                    </header>
                    <div className="flex flex-wrap -mx-5 mb-4">
                      <div className="w-full px-5">
                        {!!errorMessageNewsletter &&
                          <h5 className="heading-5 text-violetRed text-center">{errorMessageNewsletter}</h5>
                        }
                        {!!successMessageNewsletter &&
                          <h5 className="heading-5 text-violetRed text-center">{successMessageNewsletter}</h5>
                        }
                      </div>
                      {loadingNewsletter && <Spinner />}
                    </div>
                    {!loadingNewsletter &&
                      <form onSubmit={e => handleSubmitNewsletter(e)} id="newsletter-form" className="medium-title-book text-brightTurquoiseApprox">
                        <div className="flex flex-wrap -mx-5 mb-12">
                          <div className="w-full px-5">
                            <input type="hidden" name="u" value="8" />
                            <input type="hidden" name="f" value="8" />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" value="0" />
                            <input type="hidden" name="m" value="0" />
                            <input type="hidden" name="act" value="sub" />
                            <input type="hidden" name="v" value="2" />
                            <input type="hidden" name="or" value="f1221d513fe3ab48fb5e6a2dc3d82af0" />
                            <label className="sr-only" htmlFor="email">{email}</label>
                            <input onChange={handleInputChangeNewsletter} value={formNewsletterValues.email} id="email" name="email" type="email" className="form-input py-2 w-full bg-transparent border-b border-brightTurquoiseApprox focus:outline-none placeholder-brightTurquoiseApprox" placeholder={email + "*"} required />
                          </div>
                          <div className="mt-6 w-full px-5 text-center">
                            <button type="submit" className="btn btn--turquoise">{texts.send}</button>
                          </div>
                        </div>
                      </form>
                    }
                  </div>
                </section>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default forwardRef(NewsletterDialog);