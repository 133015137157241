import React, { useState, useEffect } from 'react';
import NewsCard from '../cards/newsCard';
import Aos from 'aos';
import 'aos/dist/aos.css';

const NewsSection = ({ paragraph, newsitems, loadMoreButtonText, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);


  // Category translations
  let allCategory = nodeLocale === 'en-US' ? 'All' : 'Kaikki';
  let blogCategory = nodeLocale === 'en-US' ? 'Blog' : 'Blogi';
  let newsCategory = nodeLocale === 'en-US' ? 'News' : 'Uutiset';
  let storiesCategory = nodeLocale === 'en-US' ? 'Stories' : 'Tarinat';
  let guidesCategory = nodeLocale === 'en-US' ? 'Guides' : 'Oppaat';

  // Initial amount to show and add on load more button press
  const itemAmount = 10;

  const [category, chooseCategory] = useState('All');
  const [itemsShown, setItemsShown] = useState(itemAmount);
  let filteredNewsItems = newsitems.filter(item => category === 'All' ? item : item.newsType.includes(category))
  let filteredNewsItemsLimited = filteredNewsItems.slice(0, itemsShown - 1);

  return (
    <section className="news-section px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="max-w-screen-xl mx-auto mb-24" style={{paddingTop: 80}}>
        <header className="mx-auto max-w-xxl">
          <h3 className="mb-16 lg:mb-24 heading-2 text-canary">{paragraph}</h3>
        </header>

        <div className="mx-auto max-w-lg">
          <ul className="-mx-1 lg:-mx-3 mb-8 flex flex-wrap">
            <li className="px-1 lg:px-3">
              <button onClick={() => { chooseCategory('All'); setItemsShown(itemAmount); }} style={{ maxWidth: '150px' }} className={`category mb-2 lg:mb-6 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full ${category === 'All' ? 'bg-brightTurquoiseApprox' : 'bg-silverChalice'} hover:shadow-persimon`}>{allCategory}</button>
            </li>
            <li className="px-1 lg:px-3">
              <button onClick={() => { chooseCategory('Blog'); setItemsShown(itemAmount); }} style={{ maxWidth: '150px' }} className={`category mb-2 lg:mb-6 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full ${(category === 'Blog' || category === 'All') ? 'bg-springGreen' : 'bg-silverChalice'}  hover:shadow-springGreen`}>{blogCategory}</button>
            </li>
            <li className="px-1 lg:px-3">
              <button onClick={() => { chooseCategory('News'); setItemsShown(itemAmount); }} style={{ maxWidth: '150px' }} className={`category mb-2 lg:mb-6 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full  ${(category === 'News' || category === 'All') ? 'bg-anakiwaApprox' : 'bg-silverChalice'}  hover:shadow-anakiwaApprox`}>{newsCategory}</button>
            </li>
            <li className="px-1 lg:px-3">
              <button onClick={() => { chooseCategory('Stories'); setItemsShown(itemAmount); }} style={{ maxWidth: '150px' }} className={`category mb-2 lg:mb-6 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full  ${(category === 'Stories' || category === 'All') ? 'bg-tickleMePink' : 'bg-silverChalice'}  hover:shadow-tickleMePink`}>{storiesCategory}</button>
            </li>
            <li className="px-1 lg:px-3">
              <button onClick={() => { chooseCategory('Guides'); setItemsShown(itemAmount); }} style={{ maxWidth: '150px' }} className={`category mb-2 lg:mb-6 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full  ${(category === 'Guides' || category === 'All') ? 'bg-heliotrope' : 'bg-silverChalice'} hover:shadow-heliotrope`}>{guidesCategory}</button>
            </li>
          </ul>
        </div>

        <div className="max-w-screen-xl mx-auto mb-24 grid xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-10">
          {filteredNewsItemsLimited.map((newsitem, index) => {
            return (
              <NewsCard
                key={index}
                image={newsitem.mainImage?.gatsbyImageData}
                excerpt={newsitem.excerpt}
                newsType={newsitem.newsType}
                slug={newsitem.slug}
                date={newsitem.date}
                nodeLocale={nodeLocale}
                textColor={newsitem.setArticleCardTextColor}
              />
            )
          })}
        </div>
      </div>

      {(filteredNewsItemsLimited.length !== filteredNewsItems.length && filteredNewsItemsLimited.length > 0) &&
        <div className="mt-12 text-center pb-40">
          <button className="btn btn--turquoise" onClick={() => setItemsShown(itemsShown + itemAmount)}>
            {loadMoreButtonText}
          </button>
        </div>}

    </section>
  )
}

export default NewsSection;
