import React, { useEffect } from 'react';
import { RichText } from '../richText/richText';
import Aos from 'aos';
import 'aos/dist/aos.css';

const NumberedListSection = ({ title, listArea, buttonLabel, buttonLink, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="table-with-column-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl border border-brightTurquoiseApprox rounded-3xl">
        <div className="md:grid md:grid-cols-2">
            {listArea.map((listItem, index) => {
              return (
                <div className={`${(index%2 === 0) ? `md:border-r` : ``} ${(index < listArea.length - 2) ? `border-b` : ((index === listArea.length - 2) ? `border-b md:border-b-0` : ``)}  border-brightTurquoiseApprox`}>
                      <div className="border-brightTurquoiseApprox h-full" key={index}>
                        <div className={`md:grid md:grid-cols-6 h-full`}>
                          <div className={`px-3 pb-3 pt-3`}>
                            <h3 className="heading-2 uppercase text-brightTurquoiseApprox">
                              <span className="hidden md:block">{listItem.blockName}</span>
                              <span className="sm:block md:hidden">{index + 1}</span>
                            </h3>
                          </div>             
                          <div className="md:border-l border-brightTurquoiseApprox col-span-5 px-3 pb-3 pt-3">
                            <RichText nodeLocale={nodeLocale} raw={listItem.blockContent} />
                          </div>
                        </div>
                      </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export default NumberedListSection;