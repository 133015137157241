import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

function getColorTheme(cat) {

  let colorTheme;

  if (cat === 'All') {
    colorTheme = 'redTheme hover:shadow-persimon';
  } else if (cat === 'Blog') {
    colorTheme = 'greenTheme hover:shadow-springGreen';
  } else if (cat === 'Stories') {
    colorTheme = 'pinkTheme hover:shadow-tickleMePink';
  } else if (cat === 'Webinars') {
    colorTheme = 'violetTheme hover:shadow-heliotrope';
  } else if (cat === 'Guides') {
    colorTheme = 'violetTheme hover:shadow-heliotrope';
  } else if (cat === 'News') {
    colorTheme = 'blueTheme hover:shadow-anakiwaApprox';
  } else {
    colorTheme = 'yellowTheme hover:shadow-canary';
  };

  return colorTheme;

}

function getColorThemeForPill(cat) {

  let colorTheme;

  if (cat.toString().toLowerCase() === 'all') {
    colorTheme = 'bg-persimon';
  } else if (cat.toString().toLowerCase() === 'blog') {
    colorTheme = 'bg-springGreen';
  } else if (cat.toString().toLowerCase() === 'stories') {
    colorTheme = 'bg-tickleMePink';
  } else if (cat.toString().toLowerCase() === 'webinars') {
    colorTheme = 'bg-heliotrope';
  } else if (cat.toString().toLowerCase() === 'guides') {
    colorTheme = 'bg-silverChalice';
  } else if (cat.toString().toLowerCase() === 'news') {
    colorTheme = 'bg-anakiwaApprox';
  } else {
    colorTheme = 'bg-canary';
  };

  return colorTheme;
}



const NewsCard = ({ excerpt, image, newsType, slug, date, nodeLocale, textColor }) => {

  let category = '';
  if (newsType.toString().toLowerCase() === 'all') {
    category = nodeLocale === 'en-US' ? 'All' : 'Kaikki';
  } else if (newsType.toString().toLowerCase() === 'blog') {
    category = nodeLocale === 'en-US' ? 'Blog' : 'Blogi';
  } else if (newsType.toString().toLowerCase() === 'stories') {
    category = nodeLocale === 'en-US' ? 'Stories' : 'Tarinat';
  } else if (newsType.toString().toLowerCase() === 'webinars' || newsType.toString().toLowerCase() === 'guides') {
    category = nodeLocale === 'en-US' ? 'Guides' : 'Oppaat';
  } else {
    category = nodeLocale === 'en-US' ? 'News' : 'Uutiset';
  };

  let color = '';
  if (textColor == 'Red') {
    color = 'text-persimon';
  } else if (textColor == 'Green') {
    color = 'text-springGreen';
  } else if (textColor == 'Blue') {
    color = 'text-anakiwaApprox';
  } else if (textColor == 'Violet') {
    color = 'text-heliotrope';
  } else if (textColor == 'Pink') {
    color = 'text-tickleMePink';
  } else if (textColor == 'Yellow') {
    color = 'text-canary';
  } else if (textColor == 'Black') {
    color = 'text-black';
  } else {
    color = 'text-alabaster';
  };



  // Category translations
  /*
  let allCategory = nodeLocale === 'en-US' ? 'All' : 'Kaikki';
  let blogCategory = nodeLocale === 'en-US' ? 'Blog' : 'Blogi';
  let newsCategory = nodeLocale === 'en-US' ? 'News' : 'Uutiset';
  let storiesCategory = nodeLocale === 'en-US' ? 'Stories' : 'Tarinat';
  let webinarsCategory = nodeLocale === 'en-US' ? 'Webinars' : 'Webinaarit';
  */

  let firstCategory = newsType[0];
  let colorTheme = '';
  colorTheme = getColorTheme(firstCategory);

  const pills = newsType.map(item => {
    const color = getColorThemeForPill(item);
    return <div style={{ maxWidth: '150px' }} className={`category mb-9 mr-3 px-4 py-2 self-start small-category-title text-blackcurrant rounded-full ${color}`}>{category}</div>
  })

  return (

    <Link className={`news-card p-8 rounded-xl transform hover:-translate-y-1.5 transition duration-200 ease-in-out ar-box ar-box-4-5 ${colorTheme}`} to={slug}>
      <figure className="rounded-xl">
        <GatsbyImage
          image={image}
          style={{ gridArea: "1/1", position: 'absolute', top: '0', left: '0', width: '100%', zIndex: '20', height: '100%', borderRadius: '0.75rem' }}
          imgStyle={{ objectFit: 'cover', objectPosition: '0% 0%' }}
          alt=""
        />
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-20 z-30 rounded-xl"></div>
      </figure>
      <article className={`${color}`} style={{ gridArea: "1/1", position: "relative", display: "grid", zIndex: "40", height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            {pills}
          </div>
          <div className="card-date">{date}</div>
          <h3 className="heading-6 hyphens-auto">{excerpt}</h3>
          <span className="pl-2 mt-auto" style={{ fontSize: '2rem', fontWeight: 'bold' }}>→</span>
        </div>
      </article >
    </Link>
  );
}

export default NewsCard;


