import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import bgQuoteWeb from '../../assets/images/Edita_prima_quote_web.jpg';
import { useLocation } from '@reach/router';

const BackgroundImageWithQuoteSection = ({ sectionName, quote, quoteAuthorName, quoteAuthorTitle }) => {

  //const location = useLocation();
  
  // Add correct locale to url
  let sectionStyle = {
    backgroundImage: `url(${bgQuoteWeb})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
    paddingTop: 150
  };
/*
  if (!['/fi/','/en/'].includes(location.pathname)) {
    sectionStyle = {
    }
  }
*/
  //let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  return (
      <section style={ sectionStyle } className="image-with-quote-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
        <div className={`mx-auto max-w-xxl'}`}>
          <div className="md:grid md:grid-cols-3">
            <div>
            </div>
            < div>
            </div>
            < div>
              <div className="quote">”{quote}”</div>
              <div><span className="quote-author">{quoteAuthorName}</span> <span className="quote-author-title">{quoteAuthorTitle}</span></div>
            </div>
          </div>
        </div>
      </section>
    )
}
export default BackgroundImageWithQuoteSection;