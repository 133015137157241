import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Mars from '../../assets/images/mars.png';
import { browserName } from "react-device-detect";
import bgCareers from '../../assets/images/Edita_Prima_photo_bg_careers_home.jpg';
import { useLocation } from '@reach/router';

const CtaSection = ({ title, titleColor, textField, ctaLinks, textAlignCenter, backgroundVideo, nodeLocale, openDialog, openNewsletterDialog }) => {
  // Aos animation settings
  // const [loaded, setLoaded] = useState(0);
  // useEffect(() => {
  //   Aos.init({ duration: 1500, offset: 60 });
  //   setLoaded(1);
  // }, [loaded]);
  const location = useLocation();
  
  // Add correct locale to url
  let sectionStyle = {
    backgroundImage: `url(${bgCareers})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
    paddingTop: 150
  };

  if (!['/fi/','/en/'].includes(location.pathname)) {
    sectionStyle = {
    }
  }

  let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  let backgroundMedia = '';

  if (browserName == 'Safari') {
    backgroundMedia = <img className="absolute top-0 left-0 w-full h-full object-contain z-10" src={Mars} alt="" />;
  } else if (backgroundVideo) {
    backgroundMedia = <div><video className="absolute top-0 left-0 w-full h-full object-contain z-0 lg-max:hidden" width="100%" autoPlay loop muted><source src={backgroundVideo.file.url} type="video/mp4" /></video><img className="absolute top-0 left-0 w-full h-full object-contain z-10 lg:hidden" src={Mars} alt="" /></div>;
  } else {
    backgroundMedia = '';
  }

  return (backgroundVideo) ? (
    <section className="mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      {backgroundVideo &&
        <div className="mx-auto max-w-lg relative ar-box ar-box-1-1">
          {backgroundMedia}

          {title &&
            <div className="mx-auto p-4 sm:p-6 md:p-12 lg:p-16 absolute top-0 bottom-0 z-10 text-center flex items-center">
              <h2 className="mb-4 heading-4 text-white">{title}</h2>
            </div>
          }
        </div>
      }

      {
        title && !backgroundVideo &&
        <div className="mx-auto max-w-xl relative z-10 text-center">
          <h2 className="mb-4 heading-4 text-white">{title}</h2>
        </div>
      }

      <div className="mx-auto max-w-screen-lg relative z-10 text-center">
        <div className="mt-16 text-center xs:flex flex-wrap justify-center">
          {ctaLinks?.map((link, index) => {
            // Check if link is external or internal or has value contact-form that will open a contact form dialog
            let linkItem;

            if (link.linkUrl === 'contact-form') {
              linkItem = <div className="btn btn--turquoise cursor-pointer" key={index} onClick={() => openDialog()}>{link.linkName}</div>
            } else if (link.linkUrl === '#newsletter') {
              linkItem = <div className="btn btn--turquoise cursor-pointer" key={index} onClick={() => openNewsletterDialog()}>{link.linkName}</div>
            } else if (link.linkToEntry?.slug) {
              linkItem = <Link className="btn btn--turquoise" key={index} to={`/${locale}/${link.linkToEntry?.slug}`}>{link.linkName}</Link>
            } else {
              linkItem = <a className="btn btn--turquoise" href={link.linkUrl} target="_blank" rel="noreferrer">{link.linkName}</a>
            }

            return (
              <div className="mb-4 px-4" key={index}>
                {linkItem}
              </div>
            )
          })}
        </div>
      </div>
    </section >
  )
    :
    (
      <section className="mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up" style={ sectionStyle }>
        <div className={`mx-auto ${textAlignCenter ? 'text-center max-w-lg' : 'max-w-xxl'}`}>
          {title &&
            <h2 className={`mb-4 ${titleColor[0] === 'Yellow' ? 'text-canary heading-2' : 'cta-title text-alabaster'}`}>{title}</h2>
          }
          {textField &&
            <p className={`mb-16 text-alabaster ${titleColor[0] === 'Yellow' ? 'text-canary heading-6-medium' : 'medium-title-book text-alabaster'}`}>{textField.textField}</p>
          }

          <div className="relative z-10 text-center">
            <div className="mt-12 text-center xs:flex flex-wrap justify-center">
              {ctaLinks?.map((link, index) => {
                // Check if link is external or internal or has value contact-form that will open a contact form dialog
                let linkItem;

                if (link.linkUrl === 'contact-form') {
                  linkItem = <div className="btn btn--turquoise cursor-pointer" onClick={() => openDialog()}>{link.linkName}</div>
                } else if (link.linkUrl === '#newsletter') {
                  linkItem = <div className="btn btn--turquoise cursor-pointer" onClick={() => openNewsletterDialog()}>{link.linkName}</div>
                } else if (link.linkToEntry?.slug) {
                  linkItem = <Link className="btn btn--turquoise" to={`/${locale}/${link.linkToEntry?.slug}`}>{link.linkName}</Link>
                } else {
                  linkItem = <a className="btn btn--turquoise" href={link.linkUrl} target="_blank" rel="noreferrer">{link.linkName}</a>
                }

                return (
                  <div className="mb-4 px-4" key={index}>
                    {linkItem}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    )
}
export default CtaSection;