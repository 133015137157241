import React, { useEffect } from 'react';
import BannerCard from '../cards/bannerCard';
import Aos from 'aos';
import 'aos/dist/aos.css';

const BannerSection = ({ banners, newsitems, nodeLocale }) => {

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  const newsCategory = nodeLocale === 'en-US' ? 'News' : 'Uutiset';

  function mergeArray(banners, newsitems, nodeLocale) {
    // mergedArray must have the first 3 banners items.
    // From the fourth item, mergedArray will be newsitem, banner, newsitem, banner 
    let mergedArray = [];
    for (let i = 0, j = 0; i < banners.length; i++) {
      if (i < 3) {
        mergedArray.push(banners[i]);
      } else {
        if ((j < 3) && (j < newsitems.length)) {
          let newsitem = {...newsitems[j++]};
          if (newsitem.newsType) {
            if (newsitem.newsType[0].toString().toLowerCase() === 'all' || newsitem.newsType[0].toString().toLowerCase() === 'kaikki') {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'All' : 'Kaikki';
            } else if (newsitem.newsType[0].toString().toLowerCase() === 'blog' || newsitem.newsType[0].toString().toLowerCase() === 'blogi') {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'Blog' : 'Blogi';
            } else if (newsitem.newsType[0].toString().toLowerCase() === 'stories' || newsitem.newsType[0].toString().toLowerCase() === 'tarinat') {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'Stories' : 'Tarinat';
            } else if (newsitem.newsType[0].toString().toLowerCase() === 'guides' || newsitem.newsType[0].toString().toLowerCase() === 'oppaat')  {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'Guides' : 'Oppaat';
            } else if (newsitem.newsType[0].toString().toLowerCase() === 'webinars' || newsitem.newsType[0].toString().toLowerCase() === 'webinaarit')  {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'Webinars' : 'Webinaarit';
            } else {
              newsitem.newsType[0] = nodeLocale === 'en-US' ? 'News' : 'Uutiset';
            };
            mergedArray.push(newsitem);
          }
        }
        mergedArray.push(banners[i]);
      }
    }

    return mergedArray;
  }

  return (
    <section className="max-w-screen-xl mx-auto mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20 grid sm:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-10" data-aos="fade-up">
      {mergeArray(banners, newsitems, nodeLocale).map((banner, index) => {
        return banner.internal.type === 'ContentfulBanner' ? (
          <BannerCard
            key={index}
            category={banner.category}
            color={banner.color}
            title={banner.title}
            buttonLabel={banner.buttonLabel}
            buttonLink={banner.buttonLink?.slug}
            linkAnchor={banner.anchorLink}
          />
        )
          :
          (
            <BannerCard
              key={index}
              category={banner.newsType[0]}
              color={banner.color}
              title={banner.excerpt}
              buttonLabel={banner.linkText}
              buttonLink={`${newsCategory.toLowerCase()}/${banner.slug}`}
            />
          )
      })}
    </section>
  )
}

export default BannerSection;
