import React, { useEffect, useRef } from 'react';
// import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { RichText } from '../richText/richText';
import { Link } from 'gatsby';
import SimpleContactDialog from '../simpleContactDialog/simpleContactDialog';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextSection = ({ title, richText, ctaButtonLinks, nodeLocale, formData, references }) => {

  // const text = renderRichText(richText);

  let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  const dialogRef = useRef(null);

  const openDialog = () => {
    if (typeof window !== 'undefined') {
      // Click the Send Message button, datalayer
      // when the 'Send Message' button is clicked
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
      'event' : "sendMessageClick"
      });
    }
    dialogRef?.current?.openContactDialog();
  }

  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);
  return (

    <section className="text-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">

      <div className="mx-auto max-w-xxl" style={{paddingTop: 80}}>
        {title &&
          <h2 className="mb-6 heading-2 text-canary">{title}</h2>
        }
        {!!richText &&
          <RichText
            nodeLocale={nodeLocale}
            raw={richText}
          />
        }
        <SimpleContactDialog
          ref={dialogRef}
          sectionTitle={formData?.sectionTitle}
          firstName={formData?.firstName}
          lastName={formData?.lastName}
          email={formData?.email}
          texts={formData?.texts}
          ctaButtonLinks={ctaButtonLinks}
          locale={locale}
        />

        {ctaButtonLinks &&
          <div className="mt-16 text-center xs:flex flex-wrap justify-center">
            {ctaButtonLinks.map((link, index) => {
              let linkItem;
              let url = "";
              if (link?.linkUrl) {
                url = link?.linkUrl;
              } else if (link?.addAsset?.file.url) {
                url = link.addAsset.file.url;
              } else {
                url = `/${locale}/${link?.linkToEntry.slug}`;
              }
              // Check if showing collect user info form or an link
              if (link?.collectUserInfo && formData?.texts) {
                linkItem = <div className="btn btn--turquoise cursor-pointer" key={index} onClick={() => openDialog()}>{link.linkName}</div>
              } else {
                linkItem = <Link className="btn btn--turquoise" to={url}>{link.linkName}</Link>
              }

              return (
                <div className="mb-4 px-4" key={index}>
                  {linkItem}
                </div>
              )
            })}
          </div>
        }
      </div>
    </section>

  )
}

export default TextSection;