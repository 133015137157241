import React from 'react';
import { Link } from 'gatsby';

const BannerCard = ({ category, color, title, buttonLabel, buttonLink, linkAnchor }) => {
  // Color theme
  let colorTheme = '';

  if (color) {
    if (color[0] === 'Red') {
      colorTheme = 'redTheme hover:shadow-persimon';
    } else if (color[0] === 'Green') {
      colorTheme = 'greenTheme hover:shadow-springGreen';
    } else if (color[0] === 'Blue') {
      colorTheme = 'blueTheme hover:shadow-anakiwaApprox';
    } else if (color[0] === 'Violet') {
      colorTheme = 'violetTheme hover:shadow-heliotrope';
    } else if (color[0] === 'Pink') {
      colorTheme = 'pinkTheme hover:shadow-tickleMePink';
    } else {
      colorTheme = 'yellowTheme hover:shadow-canary';
    };
  }

  let linkUrl = '';

  if (linkAnchor) {
    linkUrl = buttonLink + linkAnchor;
  } else {
    linkUrl = buttonLink;
  }


  return (
    <Link className={`p-8 rounded-xl transform hover:-translate-y-1.5 transition duration-200 ease-in-out ${colorTheme}`} to={linkUrl}>
      <article className="flex flex-col h-full">
        <div className="category mb-9 px-4 py-2 self-start small-category-title bg-anakiwaApprox text-blackcurrant rounded-full">{category}</div>
        <h3 className="heading-6">{title}</h3>
        <div className="card-cta pt-12 mt-auto">{buttonLabel}<span className="pl-2">→</span></div>
      </article >
    </Link>
  );
}

export default BannerCard;