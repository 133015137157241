import React, { useEffect } from 'react';
import { RichText } from '../richText/richText';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TableWithColumnSection = ({ title, columnAreas, buttonLabel, buttonLink, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="table-with-column-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl border border-brightTurquoiseApprox rounded-3xl">
        <div className="md:grid md:grid-cols-3">
          {columnAreas.map((columnArea, index) => {
              return (
                <div className={`${((((index + 1) % 3 !== 0)) && !((index === columnAreas.length - 1) && ((index + 1) % 3 === 1))) ? 'md:border-r' : ''} border-brightTurquoiseApprox ${((index === columnAreas.length - 1) && ((index + 1) % 3 === 1)) ? 'col-span-3' : 'border-b'}`} key={index}>
                  {!(((index === columnAreas.length - 1) && ((index + 1) % 3 === 1))) &&
                    <div className="px-6 pt-6 h-48 border-b border-brightTurquoiseApprox">
                      <span className="mb-6 uppercase normal-title">{columnArea.blockName.split(":")[0]}:</span>
                      <br/>
                      <span className="mb-6 uppercase bold-title">{columnArea.blockName.split(":")[1]}</span>
                    </div>
                  }
                  {!(((index === columnAreas.length - 1) && ((index + 1) % 3 === 1))) &&
                    <div className="p-6">
                      <RichText nodeLocale={nodeLocale} raw={columnArea.blockContent} />
                    </div>
                  }
                  {(((index === columnAreas.length - 1) && ((index + 1) % 3 === 1))) &&
                    <div className="md:grid md:grid-cols-3 pt-6">
                      <div className="px-6 pb-6 h-48">
                        <span className="mb-6 uppercase normal-title">{columnArea.blockName.split(":")[0]}:</span>
                        <br/>
                        <span className="mb-6 uppercase bold-title">{columnArea.blockName.split(":")[1]}</span>
                      </div>
                      <div className="px-6 pb-6 col-span-2">
                        <RichText nodeLocale={nodeLocale} raw={columnArea.blockContent} />
                      </div>
                    </div>
                  }
                </div>
              )
          })}
        </div>
      </div>
    </section>
  )
}

export default TableWithColumnSection;