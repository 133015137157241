import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

const TextWithIconListSection = ({ title, textWithIconList, buttonLabel, buttonLink, nodeLocale }) => {
  // Aos animation settings
  useEffect(() => {
    Aos.init({ duration: 1500, offset: 60 });
  }, []);

  return (
    <section className="text-with-icon-list-section mb-16 lg:mb-24 px-4 sm:px-6 md:px-12 lg:px-20" data-aos="fade-up">
      <div className="mx-auto max-w-xxl">
        <div className="text-center justify-center">
          <h2 className="heading-2 text-brightTurquoiseApprox">{title}</h2>
        </div>
        
        {textWithIconList.map((listItem, index) => {
            return (
              <div className="md:grid md:grid-cols-6 rich-text">
                <div className="flex px-6 pb-6 pt-6 text-brightTurquoiseApprox justify-center items-center">
                  {(index === 1) && <img src={listItem.graph.file.url} alt="" style={{height: 160}} />}
                  {(index === 0 || index === 2) && <img src={listItem.graph.file.url} alt="" style={{width: 125}} />}
                  {(index === 6) && <img src={listItem.graph.file.url} alt="" style={{width: 90}} />}
                  {(index >= 3 && index <= 5) && <img src={listItem.graph.file.url} alt="" style={{width: 105}} />}
                </div>             
                <div className="col-span-5 px-6 pb-6 pt-6">
                  <div><span className="highlighted-title">{listItem.sectionName}</span> <span className="title">{listItem.sectionTitle}</span></div>
                  <div className="description">&#8594; {listItem.sectionDescription.sectionDescription}</div>
                </div>
              </div>
            )
        })}

      </div>
    </section>
  )
}

export default TextWithIconListSection;